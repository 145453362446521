import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image/withIEPolyfill"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Slider from "react-slick"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"

import CrButton from "../components/arrowButton"
import CrPlayer from "../components/CrPlayer"

import Union from '../images/Union.svg'
import "../styles/career.scss"


const CareerPage = (props) => {
  const [active, setActive] = React.useState(null)

  const findImage = (name) => {
    const image = props.data.allFile.edges.find(obj => obj.node.name === name)
    return image?.node.childImageSharp.fluid
  }

  const settings = {
    arrows: false,
    dots: true,
    swipe: true,
    fade: true,
    speed: 1000,
    slidesPerRow: 1,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false,
    autoplaySpeed: 5000,
    loop: true,
  }

  const faqToggler = (title) => {
    if(active === title) {
      setActive(null)
      return
    }
    setActive(title)
  }

  return (
    <Layout>
      <SEO
        title="Careers | CodeRapper"
        description="We are building scalable solutions for globle brands and we need your help. join us!" />
      <section className="career-banner">
        <div className="container-lg">
          <div className="extra-gutter left right">
            <Slider {...settings}>
              <div>
              <div className="career-banner__slide">
                <div className="row">
                  <div className="col-md-8">
                    <div className="career-banner__content">
                      <figure className="d-flex employee align-items-center">
                        <div className="employee__quotes text-h2">
                          <svg xmlns="http://www.w3.org/2000/svg" width="75" height="58" viewBox="0 0 75 58" fill="none">
                            <path d="M17.132 57.4C13.3987 57.4 10.2654 56.5333 7.73203 54.8C5.33203 52.9333 3.46537 50.4667 2.13203 47.4C0.798699 44.2 0.132032 40.6 0.132032 36.6C0.132032 31.6667 1.06536 26.9333 2.93203 22.4C4.7987 17.7333 7.46537 13.5333 10.932 9.8C14.532 5.93333 18.7987 2.8 23.732 0.399997L33.732 8.99999C30.132 10.3333 26.932 12.0667 24.132 14.2C21.332 16.3333 18.932 18.7333 16.932 21.4C15.0654 24.0667 13.732 26.8 12.932 29.6L13.732 30C14.3987 29.6 15.132 29.2667 15.932 29C16.8654 28.7333 18.132 28.6 19.732 28.6C21.5987 28.6 23.4654 29.1333 25.332 30.2C27.332 31.1333 28.9987 32.6 30.332 34.6C31.6654 36.6 32.332 39.2 32.332 42.4C32.332 45.3333 31.5987 47.9333 30.132 50.2C28.7987 52.4667 26.9987 54.2667 24.732 55.6C22.4654 56.8 19.932 57.4 17.132 57.4ZM58.132 57.4C54.3987 57.4 51.2654 56.5333 48.732 54.8C46.332 52.9333 44.4654 50.4667 43.132 47.4C41.7987 44.2 41.132 40.6 41.132 36.6C41.132 31.6667 42.0654 26.9333 43.932 22.4C45.7987 17.7333 48.4654 13.5333 51.932 9.8C55.532 5.93333 59.7987 2.8 64.732 0.399997L74.732 8.99999C71.132 10.3333 67.932 12.0667 65.132 14.2C62.332 16.3333 59.932 18.7333 57.932 21.4C56.0654 24.0667 54.732 26.8 53.932 29.6L54.732 30C55.3987 29.6 56.132 29.2667 56.932 29C57.8654 28.7333 59.132 28.6 60.732 28.6C62.5987 28.6 64.4654 29.1333 66.332 30.2C68.332 31.1333 69.9987 32.6 71.332 34.6C72.6654 36.6 73.332 39.2 73.332 42.4C73.332 45.3333 72.5987 47.9333 71.132 50.2C69.7987 52.4667 67.9987 54.2667 65.732 55.6C63.4654 56.8 60.932 57.4 58.132 57.4Z" fill="black"/>
                          </svg>
                        </div>
                        <figcaption className="employee__detail">
                          <h4 className="employee__name">Sarwajit</h4>
                          <p className="employee__role">Senior Software Engineer</p>
                        </figcaption>
                      </figure>
                      <h2 className="career-banner__title">
                        Every day at CodeRapper has new challenges and newer opportunities.”
                      </h2>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="career-banner__img">
                        <Img
                          fluid={findImage('sarwajit')}
                          alt="sarwajit"
                          durationFadeIn={0}
                        />
                    </div>
                  </div>
                </div>
              </div>
              </div>
              <div>
              <div className="career-banner__slide">
                <div className="row">
                  <div className="col-md-8">
                    <div className="career-banner__content">
                      <figure className="d-flex employee align-items-center">
                        <div className="employee__quotes text-h2">
                          <svg xmlns="http://www.w3.org/2000/svg" width="75" height="58" viewBox="0 0 75 58" fill="none">
                            <path d="M17.132 57.4C13.3987 57.4 10.2654 56.5333 7.73203 54.8C5.33203 52.9333 3.46537 50.4667 2.13203 47.4C0.798699 44.2 0.132032 40.6 0.132032 36.6C0.132032 31.6667 1.06536 26.9333 2.93203 22.4C4.7987 17.7333 7.46537 13.5333 10.932 9.8C14.532 5.93333 18.7987 2.8 23.732 0.399997L33.732 8.99999C30.132 10.3333 26.932 12.0667 24.132 14.2C21.332 16.3333 18.932 18.7333 16.932 21.4C15.0654 24.0667 13.732 26.8 12.932 29.6L13.732 30C14.3987 29.6 15.132 29.2667 15.932 29C16.8654 28.7333 18.132 28.6 19.732 28.6C21.5987 28.6 23.4654 29.1333 25.332 30.2C27.332 31.1333 28.9987 32.6 30.332 34.6C31.6654 36.6 32.332 39.2 32.332 42.4C32.332 45.3333 31.5987 47.9333 30.132 50.2C28.7987 52.4667 26.9987 54.2667 24.732 55.6C22.4654 56.8 19.932 57.4 17.132 57.4ZM58.132 57.4C54.3987 57.4 51.2654 56.5333 48.732 54.8C46.332 52.9333 44.4654 50.4667 43.132 47.4C41.7987 44.2 41.132 40.6 41.132 36.6C41.132 31.6667 42.0654 26.9333 43.932 22.4C45.7987 17.7333 48.4654 13.5333 51.932 9.8C55.532 5.93333 59.7987 2.8 64.732 0.399997L74.732 8.99999C71.132 10.3333 67.932 12.0667 65.132 14.2C62.332 16.3333 59.932 18.7333 57.932 21.4C56.0654 24.0667 54.732 26.8 53.932 29.6L54.732 30C55.3987 29.6 56.132 29.2667 56.932 29C57.8654 28.7333 59.132 28.6 60.732 28.6C62.5987 28.6 64.4654 29.1333 66.332 30.2C68.332 31.1333 69.9987 32.6 71.332 34.6C72.6654 36.6 73.332 39.2 73.332 42.4C73.332 45.3333 72.5987 47.9333 71.132 50.2C69.7987 52.4667 67.9987 54.2667 65.732 55.6C63.4654 56.8 60.932 57.4 58.132 57.4Z" fill="black"/>
                          </svg>
                        </div>
                        <figcaption className="employee__detail">
                          <h4 className="employee__name">Meera</h4>
                          <p className="employee__role">Senior Software Engineer</p>
                        </figcaption>
                      </figure>
                      <h2 className="career-banner__title">
                        It’s refreshing to work in a team that puts learning and innovation first.”
                      </h2>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="career-banner__img">
                        <Img
                          fluid={findImage('meera')}
                          alt="Meera k menon"
                          durationFadeIn={0}
                        />
                    </div>
                  </div>
                </div>
              </div>
              </div>
              <div>
              <div className="career-banner__slide">
                <div className="row">
                  <div className="col-md-8">
                    <div className="career-banner__content">
                      <figure className="d-flex employee align-items-center">
                        <div className="employee__quotes text-h2">
                          <svg xmlns="http://www.w3.org/2000/svg" width="75" height="58" viewBox="0 0 75 58" fill="none">
                            <path d="M17.132 57.4C13.3987 57.4 10.2654 56.5333 7.73203 54.8C5.33203 52.9333 3.46537 50.4667 2.13203 47.4C0.798699 44.2 0.132032 40.6 0.132032 36.6C0.132032 31.6667 1.06536 26.9333 2.93203 22.4C4.7987 17.7333 7.46537 13.5333 10.932 9.8C14.532 5.93333 18.7987 2.8 23.732 0.399997L33.732 8.99999C30.132 10.3333 26.932 12.0667 24.132 14.2C21.332 16.3333 18.932 18.7333 16.932 21.4C15.0654 24.0667 13.732 26.8 12.932 29.6L13.732 30C14.3987 29.6 15.132 29.2667 15.932 29C16.8654 28.7333 18.132 28.6 19.732 28.6C21.5987 28.6 23.4654 29.1333 25.332 30.2C27.332 31.1333 28.9987 32.6 30.332 34.6C31.6654 36.6 32.332 39.2 32.332 42.4C32.332 45.3333 31.5987 47.9333 30.132 50.2C28.7987 52.4667 26.9987 54.2667 24.732 55.6C22.4654 56.8 19.932 57.4 17.132 57.4ZM58.132 57.4C54.3987 57.4 51.2654 56.5333 48.732 54.8C46.332 52.9333 44.4654 50.4667 43.132 47.4C41.7987 44.2 41.132 40.6 41.132 36.6C41.132 31.6667 42.0654 26.9333 43.932 22.4C45.7987 17.7333 48.4654 13.5333 51.932 9.8C55.532 5.93333 59.7987 2.8 64.732 0.399997L74.732 8.99999C71.132 10.3333 67.932 12.0667 65.132 14.2C62.332 16.3333 59.932 18.7333 57.932 21.4C56.0654 24.0667 54.732 26.8 53.932 29.6L54.732 30C55.3987 29.6 56.132 29.2667 56.932 29C57.8654 28.7333 59.132 28.6 60.732 28.6C62.5987 28.6 64.4654 29.1333 66.332 30.2C68.332 31.1333 69.9987 32.6 71.332 34.6C72.6654 36.6 73.332 39.2 73.332 42.4C73.332 45.3333 72.5987 47.9333 71.132 50.2C69.7987 52.4667 67.9987 54.2667 65.732 55.6C63.4654 56.8 60.932 57.4 58.132 57.4Z" fill="black"/>
                          </svg>
                        </div>
                        <figcaption className="employee__detail">
                          <h4 className="employee__name">Kiran</h4>
                          <p className="employee__role">Manager, Sales & Partnerships</p>
                        </figcaption>
                      </figure>
                      <h2 className="career-banner__title">
                        Working with a truly global team adds perspective and depth to all our initiatives. ”
                      </h2>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="career-banner__img">
                      <Img
                        fluid={findImage('kiran')}
                        alt="Kiran"
                        durationFadeIn={0}
                      />
                    </div>
                  </div>
                </div>
              </div>
              </div>
            </Slider>
            <CrButton path="#job-post" type="light" arrow="down" label="View open positions" />
          </div>
        </div>
      </section>

      <section className="value-wrap">
        <div className="container-lg">
          <div className="extra-gutter left right">
            <div className="row align-items-center g-0">
              <div className="col-lg-5 d-none d-lg-block">
                <Img
                  fluid={findImage('saurab')}
                  alt="saurab"
                  durationFadeIn={0}
                />
              </div>
              <div className="col-lg-7">
                <div className="our-values">
                  <div className="section-tag black">
                    <span>Values</span>
                  </div>
                  <h2
                    className="text text-h2 text-title-font text-black2 text-fw-regular"
                  >
                    Our culture evolves, just like us.
                  </h2>
                  <Img
                    fluid={findImage('saurab_mob')}
                    alt="saurabh"
                    durationFadeIn={0}
                    className="d-lg-none"
                  />
                  <div className="value-block">
                    <div className="value-block__item">
                      <h5 className="value-block__title">Accountability</h5>
                      <p className="value-block__para">As a borderless global team of eCommerce enthusiasts, we’re constantly striving to build an environment of limitless trust and accountability.</p>
                    </div>

                    <div className="value-block__item">
                      <h5 className="value-block__title">Innovation</h5>
                      <p className="value-block__para">Innovation lies at the heart of everything we do, and we love new initiatives and ideas that propel our growth as a commerce collective.</p>
                    </div>

                    <div className="value-block__item">
                      <h5 className="value-block__title">Transparency</h5>
                      <p className="value-block__para">We believe that transparency is a two-way street. So we’re focused on building an open culture that allows people to share their thoughts and concerns with conviction.</p>
                    </div>

                    <div className="value-block__item">
                      <h5 className="value-block__title">Integrity</h5>
                      <p className="value-block__para">We’re young, we’re fast, and we’re out to change all things eCommerce. And to achieve this, we’re slowly building a decentralized workspace without compromises.</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    
      <section className="hire">
        <div className="container-lg">
          <div className="extra-gutter left right">
            <div className="section-tag white">
              <span>How we hire</span>
            </div>
            <h2
              className="text text-h2 text-title-font text-white text-fw-regular gap-4x"
            >
              Ready to join the ride?
            </h2>
            <div className="row">
              <div className="col-lg-4">
                <div className="number-block">
                  <h2 className="number-block__count">1</h2>
                  <div className="number-block__content">
                    <h5 className="number-content__title">Write to us.</h5>
                    <p className="number-content__para">Skip the cookie-cutter templates and send us an email with a write-up of your hopes, dreams, and skills. </p>
                  </div>
                </div>
              </div>

              <div className="col-lg-4">
                <div className="number-block">
                  <h2 className="number-block__count">2</h2>
                  <div className="number-block__content">
                    <h5 className="number-content__title">Talk to us.</h5>
                    <p className="number-content__para">Our team will talk to you to understand your skills and vision better. Our HR folks will reach out to get you started.</p>
                  </div>
                </div>
              </div>

              <div className="col-lg-4">
                <div className="number-block mb-0">
                  <h2 className="number-block__count">3</h2>
                  <div className="number-block__content">
                    <h5 className="number-content__title">It’s a match!</h5>
                    <p className="number-content__para">If everything goes well and our stars align, we’ll make you an offer and bring you onboard our growing team!</p>
                  </div>
                </div>
              </div>
            </div>

            
          </div>
        </div>
      </section>
    
      <section className="jobs" id="job-post">
        <div className="container-lg">
          <div className="extra-gutter left right">
            <div className="row align-items-center">
              <div className="col-md-4">
                <div className="section-tag black">
                  <span>Open positions</span>
                </div>
                <h2
                  className="text text-h2 text-title-font text-black2 text-fw-regular"
                >
                  We’re hiring specialists.
                </h2>

                <p
                  className="text text-p-md text-black3 text-fw-regular gap-1x"
                  >Join our remote team of eCommerce specialists and be a part of our growth story. Here are our currently open roles.
                </p>
              </div>
              <div className="col-md-7 offset-md-1">
                <Img
                  fluid={findImage('team')}
                  alt="team"
                  durationFadeIn={0}
                />
              </div>
            </div>

            <div className="job-list">
              {props.data.allContentfulJobs.edges.map((edge, index) => {
                return (
                  <div className="job-item">
                    <div className="job-item__designation">
                      <h5>{edge.node.jobTitle}</h5>
                      {edge.node.remoteJob 
                      ?<h6>
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="17" viewBox="0 0 16 17" fill="none">
                          <path d="M1 5.13828L1.29816 5.48415C5.15924 2.19105 10.8408 2.19105 14.7018 5.48415L15 5.13828C10.9671 1.6987 5.03288 1.6987 1 5.13828Z" fill="black"/>
                          <path d="M2.67236 7.9445L2.99518 8.26732C5.76077 5.50784 10.2382 5.50784 13.0038 8.26732L13.3266 7.9445C10.3827 5.00678 5.61628 5.00678 2.67236 7.9445Z" fill="black"/>
                          <path d="M4.93262 10.2044L5.25543 10.5272C6.77214 9.01404 9.2275 9.01404 10.7442 10.5272L11.067 10.2044C9.3719 8.51324 6.62774 8.51324 4.93262 10.2044Z" fill="black"/>
                          <circle cx="8" cy="13" r="1" fill="black"/>
                        </svg>
                        Remote
                      </h6>
                      : <h6>
                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M8.00251 4.22078C7.4525 4.22078 6.91484 4.38386 6.45751 4.68939C6.00018 4.99493 5.64371 5.4292 5.43316 5.93731C5.22262 6.44542 5.16745 7.00455 5.27465 7.54401C5.38184 8.08346 5.64657 8.57903 6.03538 8.96804C6.42419 9.35706 6.91961 9.62206 7.45901 9.72954C7.99841 9.83702 8.55757 9.78216 9.06579 9.57188C9.57401 9.36161 10.0085 9.00537 10.3143 8.5482C10.62 8.09103 10.7834 7.55346 10.7837 7.00346C10.7829 6.26594 10.4897 5.55884 9.96833 5.0372C9.44696 4.51556 8.74002 4.22196 8.00251 4.22078V4.22078ZM8.00251 9.48289C7.51105 9.48289 7.03063 9.3371 6.62206 9.06397C6.21349 8.79084 5.89513 8.40264 5.70726 7.94851C5.51939 7.49438 5.47046 6.99472 5.56667 6.51277C5.66287 6.03082 5.89988 5.58824 6.24771 5.24104C6.59553 4.89384 7.03854 4.65762 7.52066 4.56229C8.00278 4.46695 8.50235 4.51677 8.95615 4.70546C9.40994 4.89414 9.79756 5.2132 10.07 5.62227C10.3424 6.03133 10.4873 6.512 10.4864 7.00346C10.4844 7.66121 10.222 8.2914 9.75645 8.75609C9.29092 9.22077 8.66026 9.48211 8.00251 9.48289Z" fill="black"/>
                        <path d="M8.00248 2C7.13431 1.99992 6.28106 2.2258 5.52665 2.65543C4.77223 3.08506 4.14263 3.70366 3.69976 4.45037C3.25689 5.19709 3.01599 6.04622 3.00077 6.91426C2.98554 7.7823 3.19651 8.63936 3.61293 9.40115C4.82291 11.8613 7.74978 14.4135 7.87316 14.5221L8.00843 14.5875L8.12883 14.5384L8.15113 14.5221C8.28491 14.4016 11.5343 11.564 12.5882 8.99683L12.6105 8.93291L12.6596 8.80508C12.9545 8.04798 13.0601 7.23036 12.9674 6.42318C12.8746 5.616 12.5863 4.84365 12.1275 4.17314C11.6686 3.50264 11.053 2.95424 10.3342 2.57554C9.61536 2.19684 8.81497 1.99928 8.00248 2V2ZM12.3861 8.69805L12.34 8.81994L12.3147 8.88089C11.3619 11.1968 8.45734 13.8457 8.00991 14.2396C7.58627 13.868 4.97008 11.4837 3.87603 9.26291C3.49375 8.56928 3.29264 7.79041 3.29133 6.99841C3.29002 6.2064 3.48854 5.42687 3.86853 4.73198C4.24851 4.03708 4.79768 3.44929 5.46518 3.02301C6.13267 2.59673 6.89693 2.34574 7.68719 2.29329C8.47746 2.24083 9.26818 2.3886 9.98617 2.72292C10.7041 3.05724 11.3262 3.5673 11.7947 4.20587C12.2632 4.84445 12.563 5.5909 12.6664 6.37613C12.7697 7.16136 12.6733 7.95998 12.3861 8.69805V8.69805Z" fill="black"/>
                        </svg>
                        {edge.node.location}</h6>
                      }
                    </div>
                    {edge?.node?.jobType?.map(jobTime => <div className="job-item__hour">{jobTime}</div> )}
                    {edge?.node?.experience && <div className="job-item__exp">Exp {edge?.node?.experience}</div> }
                    
                    <div className="job-item__action">
                      <CrButton path={`/careers/${edge.node.slug}`} type="light" label="Apply Now" />
                    </div>
                  </div>

                )
              })}
            </div>
          </div>
        </div>
      </section>

      <section className="faq">
        <div className="container-lg">
          <div className="extra-gutter left right">
            <div className="section-tag black">
              <span>FAQ</span>
            </div>
            <h2
              className="text text-h2 text-title-font text-black2 text-fw-regular gap-2x"
            >
              Frequently asked questions
            </h2>

            <div className="faq-block">
              <h4 className={`faq-block__title ${active === 'accord1' ? 'active' : '' }`} onClick={()=> {faqToggler('accord1')}}>
                  <a>How are Sedin Technologies and CodeRapper related?
                  <img src={Union} className={`faq-icon ${active === 'accord1'
                  ? 'active' 
                  : '' }`} />
                  </a>
              </h4>
              <div className={`faq-links list-unstyled ${active === 'accord1' ? 'active' : '' }`}>
              CodeRapper is the enterprise eCommerce division of Sedin Technologies, a global technology consulting and solutions provider, and an integrated collaborative of highly specialised divisions.
              </div>
            </div>
           
            <div className="faq-block">
              <h4 className={`faq-block__title ${active === 'accord5' ? 'active' : '' }`} onClick={()=> {faqToggler('accord5')}}>
                  <a>What are the key benefits offered by the company?
                  <img src={Union} className={`faq-icon ${active === 'accord5'
                  ? 'active' 
                  : '' }`} />
                  </a>
              </h4>
              <div className={`faq-links list-unstyled ${active === 'accord5' ? 'active' : '' }`}>
              Our benefits cover complete health insurance for the whole family, life insurance and accidental insurance cover. As part of our remote-friendly environment, we offer a flexible leave policy and infrastructure support for home offices.
              </div>
            </div>

            <div className="faq-block">
              <h4 className={`faq-block__title ${active === 'accord2' ? 'active' : '' }`} onClick={()=> {faqToggler('accord2')}}>
                  <a> Are there any freelance positions open at CodeRapper?
                  <img src={Union} className={`faq-icon ${active === 'accord2'
                  ? 'active' 
                  : '' }`} />
                  </a>
              </h4>
              <div className={`faq-links list-unstyled ${active === 'accord2' ? 'active' : '' }`}>
              We currently don’t have any active freelance positions open, but we’ll never say never. If you think you have what it takes, hit us up and let’s talk. We’re always on the lookout for expert designers, developers, and marketers.
              </div>
            </div>

            <div className="faq-block">
              <h4 className={`faq-block__title ${active === 'accord3' ? 'active' : '' }`} onClick={()=> {faqToggler('accord3')}}>
                  <a>How does employee engagement work in a remote team?
                  <img src={Union} className={`faq-icon ${active === 'accord3'
                  ? 'active' 
                  : '' }`} />
                  </a>
              </h4>
              <div className={`faq-links list-unstyled ${active === 'accord3' ? 'active' : '' }`}>
              Our team is always-on, in all the right ways. To add a human touch to our remote workspace, we engage our team through weekly virtual meet-ups where we discuss ideas, inspiration, and everything in between. Our Slack channels are a hive of internal activity, and we regularly organise off-site events to build on our vision and roadmap as a team.
              </div>
            </div>

            <div className="faq-block">
              <h4 className={`faq-block__title ${active === 'accord4' ? 'active' : '' }`} onClick={()=> {faqToggler('accord4')}}>
                  <a>Can I really work remotely from anywhere in the world?
                  <img src={Union} className={`faq-icon ${active === 'accord4'
                  ? 'active' 
                  : '' }`} />
                  </a>
              </h4>
              <div className={`faq-links list-unstyled ${active === 'accord4' ? 'active' : '' }`}>
              Yes, you can! We believe that talent has no borders or boundaries, and we’re out to build cutting-edge commerce solutions for global businesses. Our processes are designed to support collaborative and creative work across geographies. All we need is a common vision!
              </div>
            </div>
          </div>
        </div>
      </section>
    
    </Layout>
  )
}

export default CareerPage
export const pageQuery = graphql`
  query MyQuery {
    allContentfulJobs {
      edges {
        node {
          id
          jobTitle
          jobType
          experience
          remoteJob
          location
          slug
        }
      }
    }
    allFile(filter: {relativeDirectory: {eq: "careers"}}) {
      edges {
        node {
          name
          childImageSharp {
            fluid(maxWidth: 700, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`


